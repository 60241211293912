<template>
  <div class="row mx-0 justify-content-center">
    <div class="col-12 mb-3 text-center font19" >
      Review your Donation
    </div>
    <div class="col-12 mb-3 text-center" >
      <div class="row mx-0 justify-content-center">
        <div class="col-sm-10 col-md-7 col-xl-6 p-0 card_item overflow">
          <DonorSummary @goToDonorPage="$emit('goToDonorPage')" />
          <div class="row mx-0 justify-content-center">
            <div class="col-12 text-center divider1">
            </div>
          </div>
          <PaymentSummary @goToPaymentPage="$emit('goToPaymentPage')" :frequency="theDetails.frequency" :startDate="theDetails.startDate"  :recurringDonationType="theDetails.recurringDonationType" :debitOrderDay="theDetails.debitOrderDay" />
          <div class="row mx-0 justify-content-center">
            <div class="col-12 text-center divider1">
            </div>
          </div>
          <div class="row mx-0 justify-content-center text-center">
            <div class="col-12 green-text-dark font12 bold mb-1 pt-2 pb-4">
              DAILY AMOUNT
            </div>
            <div class="col-12 text-center position-relative">
              <Button color="gold" :btnText="totalDisplay" class="position-absolute start-50 translate-middle abs font20 text-nowrap lh-1 position-absolute top-100 zindex" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Review Donation Summary',
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    DonorSummary: defineAsyncComponent(() => import('./DonorSummary.vue')),
    PaymentSummary: defineAsyncComponent(() => import('./PaymentSummary.vue'))
  },
  props: ['theDetails'],
  emits: ['goToDonorPage', 'goToPaymentPage'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL
    }
  },
  computed: {
    ...mapGetters([
      'saveDetails'
    ]),
    totalDisplay () {
      let total = 0
      if (this.theDetails.transactionDTO.donationDtoList.length > 0) {
        this.theDetails.transactionDTO.donationDtoList.forEach(donation => {
          total += Number(donation.amount)
        })
      }
      return this.defaultCurrency + ' ' + parseFloat(Math.round(total * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  }
}
</script>
<style scoped>
.card_item {
  /* background-color: #fff; */
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.overflow {
  overflow: visible;
}
.divider1 {
  border-bottom: 1px solid var(--green-color-dark);
}
</style>
